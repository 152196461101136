import { popupSlice } from '../reducers/popup';

export const openChangePopup =
  (name, data = null) =>
  (dispatch) => {
    dispatch(popupSlice.actions.openPopup({ name, data }));
  };

export const removePopup = () => (dispatch) => {
  dispatch(popupSlice.actions.closePopup(null));
};
