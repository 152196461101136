import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from '../LazyImage.module.css';

const LocalImage = ({ src, alt, lazyLoadFalse }) => {
  if (lazyLoadFalse) {
    return (
      <img
        src={`${process.env.NEXT_PUBLIC_ASSETS}${src}`}
        alt={alt ? alt : 'Novatr'}
      />
    );
  } else {
    return (
      <LazyLoadImage
        src={`${process.env.NEXT_PUBLIC_ASSETS}${src}`}
        alt={alt ? alt : 'Novatr'}
      />
    );
  }
};

export default LocalImage;
