import React from 'react';
import Button from '@mui/material/Button';

const PrimaryButton = ({
  color,
  variant,
  onClick,
  size,
  href,
  children,
  className,
  fullWidth,
  target,
  startIcon,
  disabled,
  disableElevation,
  disableRipple,
  type,
  style,
  endIcon,
  sx,
}: any) => {
  return (
    <Button
      fullWidth={fullWidth}
      color={color ? color : 'primary'}
      variant={variant ? variant : 'contained'}
      size={size ? size : 'medium'}
      onClick={onClick}
      className={className}
      href={href}
      target={target || '_self'}
      disabled={disabled}
      disableElevation={disableElevation}
      type={type}
      style={style}
      disableRipple={disableRipple}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={sx}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
