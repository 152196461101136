import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAppSelector } from '../../../../hooks';

const CdnImage = ({ src, alt, sizes, quality }) => {
  const width: any = useAppSelector((state) => state.device.width);
  const [imgSrc, setSrc] = useState('');

  useEffect(() => {
    if (src) {
      let w, h: any, autoFit;
      if (width < 2200 && sizes?.xxl) {
        w = sizes?.xxl?.[0] || 2200;
        h = sizes?.xxl?.[1] || 1500;

        autoFit = sizes?.xxl?.[2];
      }
      if (width < 1500 && sizes?.xl) {
        w = sizes?.xl?.[0] || 1500;
        h = sizes?.xl?.[1] || 1280;

        autoFit = sizes?.xl?.[2];
      }
      if (width < 1280 && sizes?.lg) {
        w = sizes?.lg?.[0] || 1280;
        h = sizes?.lg?.[1] || 960;

        autoFit = sizes?.lg?.[2];
      }
      if (width < 960 && sizes?.md) {
        w = sizes?.md?.[0] || 960;
        h = sizes?.md?.[1] || 600;

        autoFit = sizes?.md?.[2];
      }
      if (width < 600 && sizes?.sm) {
        w = sizes?.sm?.[0] || 600;
        h = sizes?.sm?.[1] || 480;

        autoFit = sizes?.sm?.[2];
      }
      if (width < 480 && sizes?.xs) {
        w = sizes?.xs?.[0] || 480;
        h = sizes?.xs?.[1] || 320;

        autoFit = sizes?.xs?.[2];
      }

      if (!w) {
        w = width;
        h = Math.ceil((width * 9) / 16);
      }

      if (autoFit === undefined) {
        autoFit = true;
      }

      setSrc(
        `${process.env.NEXT_PUBLIC_CDN}/filters:quality(${quality || 75})/${
          autoFit ? 'fit-in/' : ''
        }${w}x${h}${src}`,
      );
    }
  }, [width, src, sizes, quality]);

  return <LazyLoadImage src={`${imgSrc}`} alt={alt ? alt : 'Novatr'} />;
};

export default CdnImage;
