import React, { Children, Fragment } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';

const ActiveLink = ({ router, classes, children, ...props }: any) => {
  let child = <span />;
  try {
    child = children ? Children.only(children) : <span />;
  } catch (error) {
    console.log(error);
  }

  const target = props.target ? props.target : '_self';
  const onClick = props.onClick;

  const activeClass: any = props.activeClassName
    ? props.activeClassName
    : 'active';

  let className: any = child.props.className || '';
  if (router.asPath === props.href && activeClass) {
    className = `${className} ${activeClass}`.trim();
  }

  if (props.activeClassName) delete props.activeClassName;

  delete props.loader;
  delete props.workshopTiles;
  delete props.title;
  delete props.target;
  delete props.onClick;

  return props.href === '' ||
    props.href === undefined ||
    props.href === null ? (
    <Fragment>{React.cloneElement(child, { className })}</Fragment>
  ) : (
    <Link {...(props as any)}>
      <a
        className={classes ? classes : ''}
        // title={title}
        target={target}
        onClick={onClick}
        data-active={router.asPath === props.href ? 'true' : 'false'}
      >
        {React.cloneElement(child, { className })}
      </a>
    </Link>
  );
};

export default withRouter(ActiveLink);
