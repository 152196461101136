import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import LocalImage from './LocalImage';
import CdnImage from './CdnImage';

import styles from './LazyImage.module.css';

interface Props {
  src: string;
  lazyLoadFalse?: boolean;
  alt?: string;
  className?: string;
  onClick?: any;
  quality?: any;
  radius?: any;
  sizes?: any;
  style?: any;
  sx?: any;
}

const LazyImage = ({
  src,
  lazyLoadFalse,
  alt,
  className,
  onClick,
  quality,
  radius,
  sizes,
  style,
  sx,
}: Props) => {
  const isJpg = src?.indexOf('.jpg') >= 0 || src?.indexOf('.jpeg') >= 0;

  if (
    src?.toLowerCase().search('.svg') >= 0 ||
    src?.toLowerCase().search('.gif') >= 0
  ) {
    return (
      <Box
        style={style}
        borderRadius={radius || '0'}
        overflow={radius ? 'hidden' : 'unset'}
        className={`${className} ${isJpg ? styles.lazyJpg : ''}`}
        onClick={onClick}
        sx={sx}
      >
        <LocalImage src={src} lazyLoadFalse={lazyLoadFalse} alt={alt} />
      </Box>
    );
  } else {
    return (
      <Box
        style={style}
        borderRadius={radius || '0'}
        overflow={radius ? 'hidden' : 'unset'}
        className={`${className ? className : ''} ${
          isJpg ? styles.lazyJpg : ''
        }`}
        onClick={onClick}
        sx={sx}
      >
        <CdnImage src={src} alt={alt} quality={quality} sizes={sizes} />
      </Box>
    );
  }
};

export default LazyImage;
